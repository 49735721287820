import React, { useState } from "react";
import { callBackendAPI } from "../../../helpers/apiCalls";
import { useNavigate } from "react-router";
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import { publisProductId, setPublishTrue } from "../../../redux/slices/publish-product-slice";

function convertHTMLToJSON(htmlString) {
  htmlString = htmlString?.replace("<ul>", "").replace("</ul>", "");
  var liItems = htmlString?.split("</li>");
  liItems = liItems?.filter((item) => item.trim() !== "");
  liItems = liItems?.map((item, index) => {
    return item?.replace("<li>", "");
  });
  return liItems;
}
const TopBar = ({
  productData: otherThanVariantsData,
  productPrice,
  showSalePrice,
  id,
  sectionsImages,
  step,
  setStep,
  propertiesLength,
  shipsFrom,
  setShipsFrom,
  labelName, setLabelName,
  productImages,
  loading, setLoading,
  setSaveOrPublish
}) => {

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { productData, mediaImages, productBulletBenefits, totalEnabledVariants,
    totalAllowedVariants, editViewStyle, variantsArrangedIndexesObject } = useSelector(
      (store) => store.productVariants
    );
  const { countryMethod } = useSelector(store => store.shippingConfiguration)
  const { staticMediaImages } = useSelector(
    (store) => store?.staticMediaImagesSlice
  );
  
  const alertUser = (e) => { e.preventDefault(); e.returnValue = ""; };
  const updateProduct = async (key) => {
    
    window.addEventListener("beforeunload", alertUser);
    setSaveOrPublish(key)
    if (totalEnabledVariants > totalAllowedVariants) {
      message.error("Enabled variants exceed the limit allowed!")
      return;
    }
    dispatch(publisProductId(id))
    const { productTemplate, aliexpress_images, product_variants } = productData.data;

    let updatedBulletBenefits;
    if (typeof productTemplate?.bulletBenefits === "string") {
      updatedBulletBenefits = convertHTMLToJSON(productTemplate.bulletBenefits);
    } else {
      updatedBulletBenefits = productTemplate?.bulletBenefits;
    }
    const updatedVariant = product_variants.map(variant => ({
      ...variant,
      price: parseInt(productPrice.price) || 0,
      discounted_price: parseInt(productPrice.salePrice) || 0,
      enableSalePrice: showSalePrice,
    }));

    const editView = editViewStyle === 'Dropdown' ? 'dropdown' : 'button';

    const templateData = {
      ...productData.data,
      aliexpress_images: [...productImages, ...mediaImages],
      media_images:staticMediaImages,

      productTemplate: {
        ...otherThanVariantsData,
        variantDisplayStyle: editView,
        bulletBenefits: convertHTMLToJSON(productBulletBenefits),
      },
      sectionImages: sectionsImages,
    };

    const singleVariant = {

      ...productData.data,
      product_variants: updatedVariant,
      aliexpress_images: [...productImages, ...mediaImages],
      media_images:staticMediaImages,
      productTemplate: {
        ...otherThanVariantsData,
        bulletBenefits: convertHTMLToJSON(productBulletBenefits),
      },
      sectionImages: sectionsImages,
    }
    const finalData = propertiesLength > 1 ? templateData : singleVariant;

    setLoading(true);
    const endpoint = "template/v1_edit_save_template";
    const method = "POST";
    const payload = {
      payload: {
        shipsFrom: shipsFrom ? shipsFrom : null,
        ...finalData,
        shippingConfiguration: countryMethod,
        variantsArrangedObject: variantsArrangedIndexesObject,
        action: key,
      },
    };
    console.log("payload:", payload);
    try {
      const response = await callBackendAPI(endpoint, method, {}, payload);
      if (response?.status) {

        setLoading(false);
        if (key === 'publish') {
          const { data } = response
          const { imageUpload } = data
          console.log('this s response from jjd', response);
          dispatch(setPublishTrue(imageUpload))

        }
         navigate("/");
      }
      else {
        message.error(response?.message ? response?.message : 'Error publishing product!')
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      setLoading(false);
      message.error(`Cannot update due to ${error.message}`, 2);
    } finally {
      window.removeEventListener("beforeunload", alertUser);
    }
  };
  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 24, color: "#6941c6", fontWeight: "bold" }}
      spin
    />
  );

  const showDeleteConfirmationModal = () => {
    if (productData?.productStatus === "import") {
      setIsModalVisible(true);
    } else {
      navigate("/");
    }
  };
  const openProductInAliExpress =() =>{
    console.log('datatatatatata',productData?.data?.productData?.aliexpress_product_id);
    
    window.open(`https://www.aliexpress.com/item/${productData?.data?.productData?.aliexpress_product_id}.html?`)
  }

  return (
    <>
      <div className="top-bar-main-container">
        <div className="flex gap-2 items-center">

        <button
          onClick={showDeleteConfirmationModal}
          type="button"
          className="top-bar-main-container-white-button "
        >
          Cancel
        </button>
        <button
          onClick={openProductInAliExpress}
          type="button"
          className="top-container-right-btns-container-button  "
        >
        Open in AliExpress 
        </button>
        </div>
        {propertiesLength > 1 ? (
          <div className="steps-buttons">
            <button
              onClick={() => setStep("Product template")}
              disabled={step === "Product template"}
              type="button"
              className={` ${step === "Product template"
                ? "top-bar-main-container-middle-buttons"
                : "top-bar-main-container-middle-buttons-override"
                }`}
            >
              <p>
                <b>Step 1 {"  "}</b>
                Product template
              </p>
            </button>
            <span className="top-bar-main-container-middle-buttons-vector"></span>

            <button
              onClick={() => setStep("Variant pricing")}
              disabled={step === "Variant pricing"}
              type="button"
              className={` ${step === "Variant pricing"
                ? "top-bar-main-container-middle-buttons"
                : "top-bar-main-container-middle-buttons-override"
                }`}
            >
              <p>
                <b>Step 2 </b>Variant pricing
              </p>
            </button>
            <span className="top-bar-main-container-middle-buttons-vector"></span>
            <button
              onClick={() => setStep("Shipping configuration")}
              disabled={step === "Shipping configuration"}
              type="button"
              className={` ${step === "Shipping configuration"
                ? "top-bar-main-container-middle-buttons"
                : "top-bar-main-container-middle-buttons-override"
                }`}
            >
              <p>
                <b>Step 3 </b>
                Shipping configuration
              </p>
            </button>
          </div>
        ) : (
          <button
            disabled
            type="button"
            className="top-bar-main-container-middle-button">
            Product template
          </button>
        )}

        <div className="top-container-right-btns-container">
          <button
            onClick={() => updateProduct("draft")}
            type="button"
            className="top-bar-main-container-white-button"
            disabled={loading}
          >
            Save as draft
          </button>
          <button
            onClick={() => {
              if (propertiesLength > 1) {
                if (step === 'Product template') {
                  setStep('Variant pricing')
                }
                if (step === 'Variant pricing') {
                  setStep('Shipping configuration')
                }
                if (step === 'Shipping configuration') {
                  updateProduct("publish")
                }
              }
              else {
                updateProduct("publish")
              }
            }}
            type="button"
            className="top-container-right-btns-container-button"
            disabled={loading}
          >
            {propertiesLength === 1 ? 'Publish'
              :
              propertiesLength > 1 && step === 'Shipping configuration' ? 'Publish' : 'Next'
            }
          </button>
        </div>
      </div>
      {isModalVisible && (
        <DeleteConfirmationModal
          OpenModal={isModalVisible}
          setOpModal={setIsModalVisible}
          id={id}
        />
      )}
    </>
  );
};

export default TopBar;